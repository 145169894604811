import React, { useEffect } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"

export const query = graphql`
  query ApplicationQuery {
    strapiApplicationPage {
      title
      content
      form
    }
  }
`

const ApplicationPage = ({ data }) => {
  const applicationPage = data.strapiApplicationPage

  function markupApplicationPageContent() {
    return { __html: applicationPage.content }
  }

  function markupApplicationPageForm() {
    return { __html: applicationPage.form }
  }

  useEffect(() => {
    const script = document.createElement("script")
    script.src = "/js/application.js"

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  useEffect(() => {
    let customSelect = document.createElement("script")
    customSelect.src = "/js/_1_custom-select.js" // 👈 make sure to use the correct path
    customSelect.id = "custom-select"
    document.body.appendChild(customSelect)

    return () => {
      document.body.removeChild(customSelect)
    }
  }, [])

  return (
    <Layout>
      <div className="padding-bottom-xxl position-relative z-index-1">
        <article className="article">
          <div className="container max-width-adaptive-md padding-top-xl margin-bottom-lg">
            <h1 className="text-xxxl text-center">{applicationPage.title}</h1>
          </div>
          <div className="container max-width-adaptive-md text-component line-height-lg text-space-y-md margin-bottom-lg bg-contrast-lower padding-md">
            <div dangerouslySetInnerHTML={markupApplicationPageContent()} />
          </div>
          <div
            className="freedback container max-width-adaptive-sm "
            dangerouslySetInnerHTML={markupApplicationPageForm()}
          />
        </article>
      </div>
    </Layout>
  )
}

export default ApplicationPage
